.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.myDatePicker {
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  height: 52px;
  margin-top: 12px;
  padding: 0px 12px;
}


@font-face {
  font-family: "Light";  
  src: local("Poppins-Light"),
      url("./font/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";  
  src: local("Poppins-Regular"),
      url("./font/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";  
  src: local("Poppins-Medium"),
      url("./font/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SemiBold";  
  src: local("Poppins-SemiBold"),
      url("./font/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";  
  src: local("Poppins-Bold"),
      url("./font/Poppins-Bold.ttf") format("truetype");
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
  z-index: 1000;
}

.btn-green{
  background-color:#39A463;
  height:50px;
  width: 90%;
  border-radius:12px;
  border: 1px solid #39A463;
  font-family: "SemiBold";
  font-size: 18px;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.btn-grey{
  background-color:#F0F0F0;
  height:50px;
  width: 90%;
  border-radius:12px;
  border: 1px solid #F0F0F0;
  font-family: "SemiBold";
  font-size: 18px;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.btn-bordeaux{
  background-color:#8B1B1A;
  height:44px;
  width: 100%;
  border-radius:12px;
  border: 2px solid #8B1B1A;
  font-family: "SemiBold";
  font-size: 14px;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.btn-bordeaux-outline{
  background-color:#FFFFFF;
  height:44px;
  width: 100%;
  border-radius:12px;
  border: 2px solid #8B1B1A;
  font-family: "SemiBold";
  font-size: 14px;
  color: #8B1B1A;
  align-items: center;
  justify-content: space-between;
}

.btn-black{
  background-color:#1C1C1A;
  height:44px;
  width: 100%;
  border-radius:12px;
  border: 2px solid #1C1C1A;
  font-family: "SemiBold";
  font-size: 14px;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.btn-black-outline{
  background-color:#FFFFFF;
  height:44px;
  width: 100%;
  border-radius:12px;
  border: 2px solid #1C1C1A;
  font-family: "SemiBold";
  font-size: 14px;
  color: #1C1C1A;
  align-items: center;
  justify-content: space-between;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

body {
  zoom: 0.9;
}

@media screen and (max-width: 667px) {
  body {
      overflow-x: hidden !important;
  }
  .container {
      max-width: 100% !important;
      overflow-x: hidden !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
